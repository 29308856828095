<!--
List all accounts found in the firestore /AllAccounts and allows editing of permissions.
This is an account object format:
{
    uid: account.uid,
    displayName: account.displayName,
    email: account.email,
    photoURL: account.photoURL,
    createdAt: firebase.firestore.FieldValue.serverTimestamp(),
}
Permissions are properties on the account object.
There are 2 accesses: fnstats and notes.
-->
<template>
    <div>
        <b-table :items="accounts" :fields="fields">
            <template #cell(photoURL)="row">
                <img :src="row.item.photoURL" alt="User photo" style="max-width: 100%; max-height: 100%;">
            </template>

            <template #cell(fnstats)="row">
                <div class="form-check">
                    <input class="form-check-input" type="checkbox" v-model="row.item.fnstats" :id="'fnstats' + row.index" @change="updateAccount(row.item)">
                    <label class="form-check-label" :for="'fnstats' + row.index">
                        Allow access
                    </label>
                </div>
            </template>
            <template #cell(notes)="row">
                <div class="form-check">
                    <input class="form-check-input" type="checkbox" v-model="row.item.notes" :id="'notes' + row.index" @change="updateAccount(row.item)">
                    <label class="form-check-label" :for="'notes' + row.index">
                        Allow access
                    </label>
                </div>
            </template>
            <template #cell(vid)="row">
                <div class="form-check">
                    <input class="form-check-input" type="checkbox" v-model="row.item.vid" :id="'vid' + row.index" @change="updateAccount(row.item)">
                    <label class="form-check-label" :for="'vid' + row.index">
                        Access
                    </label>
                </div>
            </template>
            <template #cell(createdAt)="row">
                {{ new Date(row.item.createdAt.seconds * 1000).toLocaleString() }}
            </template>
        </b-table>
    </div>
</template>

<script>
import { db } from '@/services/db'
import { getLog } from '@/services/log'
const log = getLog('Accounts.vue')

export default {
    data() {
        return {
            accounts: [],
            fields: [
                { key: 'uid', label: 'UID' },
                { key: 'displayName', label: 'Display Name' },
                { key: 'email', label: 'Email' },
                { key: 'photoURL', label: 'Photo' },
                { key: 'createdAt', label: 'Created At' },
                { key: 'fnstats', label: 'fnstats Access' },
                { key: 'notes', label: 'notes Access' },
                { key: 'vid', label: 'vid Access' }
            ]
        }
    },
    firestore: {
        accounts: db.collection('AllAccounts')
    },
    methods: {
        updateAccount(account) {
            let data = {
                fnstats: account.fnstats,
                notes: account.notes,
                vid: account.vid,
            };
            db.collection('AllAccounts').doc(account.uid).update(data);
            log.log(`Updated permissions for account ${account.uid}`, data);
        }
    }
}
</script >
